import axios from "axios";

const baseUrl = 'https://admin.gndm.it/api'
export const apiService = {
    getPopupPalazzo: async (id: any) => {
        try {
            return await axios.get(`${baseUrl}/contents/popup-palazzo/${id}`);
        } catch (error) {
            throw error;
        }
    },
    getAudioguida: async (id: any) => {
        try {
            return await axios.get(`${baseUrl}/contents/audioguida/${id}`);
        } catch (error) {
            throw error;
        }
    },
    getTranslations: async (language: string) => {
        try {
            return await axios.get(`${baseUrl}/translations/json/`, {
                params: {
                    language: language,
                }
            });
        } catch (error) {
            throw error;
        }
    },
};
