import React, {useEffect} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, RoomFloorModalOpen} from "../../store/slices/main.slice";
import IntFloorPlanPianoTerra1 from "./vt-floor-map-contents/Piano terra/Piano_Terra_P1-Piazza";
import IntFloorPlanPianoTerra2 from "./vt-floor-map-contents/Piano terra/Piano_Terra_P2-Ingresso";
import IntFloorPlanPianoTerra3 from "./vt-floor-map-contents/Piano terra/Piano_Terra_P3-Cortile";
import IntFloorPlanPianoTerra4 from "./vt-floor-map-contents/Piano terra/Piano_Terra_P4-Giardino";
import IntFloorPlanPianoSecondo1 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P1_SALA-A1";
import IntFloorPlanPianoTerra6 from "./vt-floor-map-contents/Piano terra/Piano_Terra_P6-Cappelletta";
import IntFloorPlanPianoSecondo2 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P2-SALA-A2";
import IntFloorPlanPianoSecondo3 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P3-SALA-A3";
import IntFloorPlanPianoSecondo4 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P4-SALA-A5";
import IntFloorPlanPianoSecondo5 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P5-SALA-B";
import IntFloorPlanPianoSecondo6 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P6-SALA-C";
import IntFloorPlanPianoSecondo7 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P7-SALA-D3";
import IntFloorPlanPianoSecondo8 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P8-SALA-E2_A-B";
import IntFloorPlanPianoSecondo9 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P10-SALA-E3";
import IntFloorPlanPianoSecondo10 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P11-SALA-E4";
import IntFloorPlanPianoSecondo11 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P12-SALA-E6";
import IntFloorPlanPianoSecondo12 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P12-Torre";
import IntFloorPlanPianoSecondo13 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P14-SALA-F1";
import IntFloorPlanPianoSecondo14 from "./vt-floor-map-contents/Piano secondo/Piano_Secondo_P1_SALA-A1333";
import IntFloorPlanSotterranei1 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P1_Sala-F1";
import IntFloorPlanSotterranei2 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P2_Disimpegno";
import IntFloorPlanSotterranei3 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P3_Sala-A2";
import IntFloorPlanSotterranei4 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P4_Sala-B1";
import IntFloorPlanSotterranei5 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P5_Sala-D";
import IntFloorPlanSotterranei6 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P6_Sala-B4_alto-basso";
import IntFloorPlanSotterranei7 from "./vt-floor-map-contents/Sotterranei/Piano_Seminterrato_P8_Sala-A1";
import {useTranslation} from "react-i18next";

const RoomFloorMapModalRoot = () => {
    const dispatch: any = useDispatch();
    const { roomFloorModal, currentRoomIndex } = useSelector(mainSelector);
    const { t }: any = useTranslation('translation');

    useEffect(() => {
        dispatch(RoomFloorModalOpen(false))
    }, []);

    const onClose = () => {
        dispatch(RoomFloorModalOpen(false))
    }

    return (
        <AnimatePresence>
            {roomFloorModal.open && (
                <motion.div
                    key="shapeModal-root"
                    className="fixed top-0 left-0 w-full h-full bg-black/40 flex justify-center items-center z-[100]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.5 } }}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="relative p-[80px] py-[120px] w-full h-full bg-[#302e2b] flex flex-col items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5, delay: 0 } }}
                        exit={{ opacity: 0, transition: { duration: 0.5 }}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="w-[80vw] lg:w-[60vw]">
                            <button onClick={onClose} className="text-white text-md font-untitledSans leading-[15px] absolute top-[30px] right-[30px]">X</button>
                            <div className="flex flex-col gap-[30px] items-center w-full mt-[-50px]">
                                <h4 className="text-white text-xs lg:text-base font-untitledSans mb-[30px] text-center">{t('vt.virtual_tour_floor_map_modal')}:</h4>
                                <div className="w-full max-h-[300px] md:max-h-[200px] lg:max-h-[300px] flex flex-col items-center justify-center cursor-pointer">
                                    {currentRoomIndex === 1 && <IntFloorPlanPianoTerra1/>}
                                    {currentRoomIndex === 2 && <IntFloorPlanPianoTerra2/>}
                                    {currentRoomIndex === 3 && <IntFloorPlanPianoTerra3/>}
                                    {currentRoomIndex === 4 && <IntFloorPlanPianoTerra4/>}
                                    {currentRoomIndex === 5 && <IntFloorPlanPianoTerra6/>}

                                    {currentRoomIndex === 6 && <IntFloorPlanPianoSecondo1/>}
                                    {currentRoomIndex === 7 && <IntFloorPlanPianoSecondo2/>}
                                    {currentRoomIndex === 8 && <IntFloorPlanPianoSecondo3/>}
                                    {currentRoomIndex === 9 && <IntFloorPlanPianoSecondo4/>}
                                    {currentRoomIndex === 10 && <IntFloorPlanPianoSecondo5/>}
                                    {currentRoomIndex === 11 && <IntFloorPlanPianoSecondo6/>}
                                    {currentRoomIndex === 12 && <IntFloorPlanPianoSecondo7/>}
                                    {currentRoomIndex === 13 && <IntFloorPlanPianoSecondo8/>}
                                    {currentRoomIndex === 14 && <IntFloorPlanPianoSecondo8/>}
                                    {currentRoomIndex === 15 && <IntFloorPlanPianoSecondo9/>}
                                    {currentRoomIndex === 16 && <IntFloorPlanPianoSecondo10/>}
                                    {currentRoomIndex === 17 && <IntFloorPlanPianoSecondo11/>}
                                    {currentRoomIndex === 18 && <IntFloorPlanPianoSecondo12/>}
                                    {currentRoomIndex === 19 && <IntFloorPlanPianoSecondo13/>}
                                    {currentRoomIndex === 20 && <IntFloorPlanPianoSecondo14/>}

                                    {currentRoomIndex === 21 && <IntFloorPlanSotterranei1/>}
                                    {currentRoomIndex === 22 && <IntFloorPlanSotterranei2/>}
                                    {currentRoomIndex === 23 && <IntFloorPlanSotterranei3/>}
                                    {currentRoomIndex === 24 && <IntFloorPlanSotterranei4/>}
                                    {currentRoomIndex === 25 && <IntFloorPlanSotterranei5/>}
                                    {currentRoomIndex === 26 && <IntFloorPlanSotterranei6/>}
                                    {currentRoomIndex === 27 && <IntFloorPlanSotterranei6/>}
                                    {currentRoomIndex === 28 && <IntFloorPlanSotterranei7/>}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default RoomFloorMapModalRoot;
