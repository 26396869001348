import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import ImageSider from "../layout/ImageSider";
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, SetAnimation, ShapeModalId, ShapeModalOpen} from "../../store/slices/main.slice";
import {apiService} from "../../services/api.service";
import {useTranslation} from "react-i18next";

const ShapesModalRoot = ({ children }: any) => {
    const dispatch: any = useDispatch();
    const { shapeModal } = useSelector(mainSelector);
    const [data, setData] = useState<any>(null)
    const { i18n, t }: any = useTranslation('translation');

    useEffect(() => {
        if(shapeModal.open && shapeModal.id) {
            apiService.getPopupPalazzo(shapeModal.id)
                .then((res) => {
                    setData(res.data)
                })
        }
    }, [shapeModal.open]);

    useEffect(() => {
        dispatch(SetAnimation('reset'))
        dispatch(ShapeModalOpen(false))
        dispatch(ShapeModalId(null))
    }, []);

    const onClose = () => {
        dispatch(SetAnimation('reset'))
        dispatch(ShapeModalOpen(false))
        dispatch(ShapeModalId(null))
    }

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);

    const checkScroll = () => {
        const div = scrollableDivRef.current;
        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        const div = scrollableDivRef.current;
        if (div) {
            const preventScroll = (e: any) => e.preventDefault();
            //@ts-ignore
            div.addEventListener('wheel', preventScroll, { passive: false });

            return () => {
                //@ts-ignore
                div.removeEventListener('wheel', preventScroll);
            };
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, shapeModal]);

    const scrollDown = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const scrollUp = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };


    return (
        <AnimatePresence>
            {shapeModal?.open && data && (
                <motion.div
                    key="shapeModal-root"
                    className="fixed top-0 left-0 w-full h-full bg-black/75 lg:bg-black/65 flex lg:justify-center lg:items-center z-[100]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1, delay: 1 }}}
                    exit={{ opacity: 0, transition: { duration: 0.5, delay: 0 } }}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="pt-safe px-[20px] lg:p-6 rounded-lg w-full lg:w-[70vw]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 0.5 }}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col lg:flex-row gap-[30px]">
                            <section className="pt-0 lg:pt-[80px] hidden lg:block pb-safe lg:pb-0">
                                <div className={`w-[200px] lg:w-[400px] h-[90px] lg:h-[180px] flex flex-col items-center justify-center`}>
                                    <img src={data.floorImage} alt="Plan map" className={`h-full w-[auto]`}/>
                                </div>
                                <ImageSider images={data.gallery}/>
                            </section>
                            <section className="overflow-y-auto lg:overflow-hidden h-[100vh] lg:h-auto pb-safe lg:pb-0">
                                <div className="mb-[20px] h-max w-full flex flex-row justify-between items-end">
                                    <div className={`w-[38px] lg:w-[60px] h-[30px] lg:h-[50px] flex flex-col items-center justify-center cursor-pointer`}>
                                        <img src={data.icon} alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[30px] lg:max-h-[45px] h-full w-[auto]`}/>
                                    </div>
                                    <button onClick={onClose} className="text-white text-md font-untitledSans leading-[15px]">X</button>
                                </div>
                                <div className="lg:max-h-[400px]">
                                    <h2 className="text-white text-md lg:text-xl font-untitledSans">{data.title[i18n.language]}</h2>
                                    <p onScroll={checkScroll} ref={scrollableDivRef} className="hidden lg:block lg:scrollable-div text-white text-xs lg:text-sm font-untitledSans text-justify lg:h-[285px] lg:overflow-y-auto">{data.content[i18n.language]}</p>
                                    <p className="block lg:hidden lg:scrollable-div text-white text-xs lg:text-sm font-untitledSans text-justify lg:h-[285px] lg:overflow-y-auto">{data.content[i18n.language]}</p>
                                </div>
                                <section className="mt-[20px] flex flex-row items-center justify-between hidden lg:block">
                                    {!showScrollUp && <div/>}
                                    {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_less')}</button>}
                                    {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_more')}</button>}
                                </section>
                                <section className="pt-0 lg:pt-[80px] flex flex-col items-center lg:hidden mt-[40px] pb-safe">
                                    <div className={`w-full max-w-[400px] h-[150px] sm:h-[200px] flex flex-col items-center justify-center`}>
                                        <img src="/images/shapes-content/shape-1/mocked-map.png" alt="Plan map" className={`h-[auto] sm:h-full w-full sm:w-[auto]`}/>
                                    </div>
                                    <ImageSider images={data.gallery}/>
                                </section>
                            </section>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ShapesModalRoot;
