import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";
import {mainSelector, AGModalOpen, MenuModalOpen, InfoModalOpen, SetSound} from "../../store/slices/main.slice";
import Button from "../layout/Button";
import Draggable from 'react-draggable';
import HamburgerMenu from "../layout/HamburgerMenu";
import { isMobile, isTablet } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import {apiService} from "../../services/api.service";

const AGModalRoot = ({ children }: any) => {
    const dispatch: any = useDispatch();
    const { AGModal, sound } = useSelector(mainSelector);
    const [data, setData] = useState<any>(null)
    const { i18n, t }: any = useTranslation('translation');

    useEffect(() => {
        if(AGModal.open && AGModal.id) {
            apiService.getAudioguida(AGModal.id)
                .then((res) => {
                    setData(res.data)
                })
        }
    }, [AGModal.open]);

    const parseText = (text: any) => {
        const getFirstTwoChars = (text: string): string => {
            return text.length > 2 ? text.slice(0, 2) : text;
        };

        try {
            // Controlla se è un JSON stringificato
            const parsed = JSON.parse(text);
            // Se il parsing riesce ed è un oggetto, ritorna il risultato
            if (typeof parsed === 'object' && parsed !== null) {
                return parsed[getFirstTwoChars(i18n.language)];
            }
        } catch (error) {
            // Non fare nulla, non è un JSON valido
        }

        // Ritorna il valore originale se non è un JSON
        return text[i18n.language];
    };

    const onClose = () => {
        dispatch(AGModalOpen(false))
        setIsTitleScrolled(false)
        setIsScrolled(false)
    }

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);
    const scrollableDivRefMobile = useRef(null);

    const checkScroll = () => {
        let div: any

        if(isMobile || isTablet) {
            div = scrollableDivRefMobile.current;
        } else {
            div = scrollableDivRef.current;
        }

        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        if(isMobile || isTablet) {
            const div = scrollableDivRef.current;
            if (div) {
                const preventScroll = (e: any) => e.preventDefault();
                //@ts-ignore
                div.addEventListener('wheel', preventScroll, { passive: false });

                return () => {
                    //@ts-ignore
                    div.removeEventListener('wheel', preventScroll);
                };
            }
        } else {
            const div = scrollableDivRef.current;
            if (div) {
                const preventScroll = (e: any) => e.preventDefault();
                //@ts-ignore
                div.addEventListener('wheel', preventScroll, { passive: false });

                return () => {
                    //@ts-ignore
                    div.removeEventListener('wheel', preventScroll);
                };
            }
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, scrollableDivRefMobile, AGModal]);

    const scrollDown = () => {
        if(isMobile || isTablet) {
            if (scrollableDivRefMobile.current) {
                //@ts-ignore
                scrollableDivRefMobile.current.scrollBy({ top: 100, behavior: 'smooth' });
            }
        } else {
            if (scrollableDivRef.current) {
                //@ts-ignore
                scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
            }
        }
    };

    const scrollUp = () => {
        if(isMobile || isTablet) {
            if (scrollableDivRefMobile.current) {
                //@ts-ignore
                scrollableDivRefMobile.current.scrollBy({ top: -100, behavior: 'smooth' });
            }
        } else {
            if (scrollableDivRef.current) {
                //@ts-ignore
                scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
            }
        }
    };

    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const videoRef = useRef(null);
    const videoRefMobile = useRef(null);
    const progressRef: any = useRef(null);
    const progressRefMobile: any = useRef(null);

    const handlePlayPause = () => {
        if(sound) {
            dispatch(SetSound(false))
        }

        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        if (isPlaying) {
            video!.pause();
        } else {
            video!.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const currentTime = video!.currentTime;
        const duration = video!.duration;
        const progressPercent = (currentTime / duration) * 100;
        setProgress(progressPercent);
    };

    const handleDrag = (e: any, data: any) => {
        let video: any;

        if (isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const progressBar: any = progressRef.current;

        if (!progressBar || !video) return;

        // Ottieni la larghezza della barra di progresso e calcola il progresso in base alla posizione del drag
        const newProgress = (data.x / progressBar.offsetWidth) * 100;

        // Assicurati che il nuovo progresso sia tra 0 e 100
        if (newProgress < 0 || newProgress > 100) return;

        const newTime = (newProgress / 100) * video.duration;

        // Assicurati che newTime sia un numero valido prima di assegnarlo
        if (!isNaN(newTime) && isFinite(newTime)) {
            video.currentTime = newTime;
            setProgress(newProgress);
        }
    };

    const goToHome = () => {
        dispatch(AGModalOpen(false))
        window.location.href = `/`
    }

    const openInfo = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(true))
    }

    const openMenu = () => {
        dispatch(MenuModalOpen(true))
    }

    const divRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isTitleScrolled, setIsTitleScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (divRef.current) {
                const { scrollTop } = divRef.current;
                setIsScrolled(scrollTop > 0);
                setIsTitleScrolled(scrollTop > 65);
            }
        };

        const divElement = divRef.current;
        if (divElement) {
            //@ts-ignore
            divElement.addEventListener('scroll', handleScroll);
        }

        // Clean up the event listener on component unmount
        return () => {
            if (divElement) {
                //@ts-ignore
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [divRef, AGModal]);

    const handleTouchStart = (e: any) => {
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
        handleSeek(e.touches[0]);
    };

    const handleTouchMove = (e: any) => {
        handleSeek(e.touches[0]);
    };

    const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
    };

    const handleSeek = (touch: any) => {
        let video: any;

        if (isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const progressBar = progressRefMobile.current || progressRef.current;

        if (!progressBar || !video) return;

        const rect = progressBar.getBoundingClientRect();
        const offsetX = touch.clientX - rect.left;
        const newProgress = (offsetX / rect.width) * 100;

        if (newProgress >= 0 && newProgress <= 100) {
            const newTime = (newProgress / 100) * video.duration;

            if (isFinite(newTime)) {
                video.currentTime = newTime;
                setProgress(newProgress);
            }
        }
    };

    // QR Code scanning
    const [scanning, setScanning] = useState(false);

    const handleScanResult = (data: string) => {
        if (data) {
            window.location.href = data;
        }
    };

    const startScanner = () => {
        setScanning(true);
    };

    const stopScanner = () => {
        setScanning(false);
    };


    return (
        <AnimatePresence>
            {AGModal?.open && data && (
                <>
                    <motion.div
                        key="AGModal-root"
                        className="hidden lg:flex fixed top-0 left-0 w-full h-full bg-black/85 justify-center items-center z-[900]"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0, transition: {duration: 0.5}}}
                        onClick={onClose}
                    >
                        <header className="hidden md:block absolute top-0 right-0 flex flex-row justify-between w-full gap-[50px] p-[40px]">
                            <div>
                                <img src="/images/GNDM logo full.png" alt="Header GNDM Logo" className="max-h-[35px]"/>
                            </div>
                        </header>
                        <motion.div
                            key="AGModal-content"
                            className="p-6 rounded-lg w-[90vw] md:w-[70vw]"
                            initial={{opacity: 0}}
                            animate={{opacity: 1, transition: {duration: 0.5, delay: 0}}}
                            exit={{opacity: 0, transition: {duration: 0.5}}}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex flex-col md:flex-row gap-[10px] md:gap-[50px]">
                                <div className="md:hidden flex flex-col w-full items-center h-max mb-[-20px]">
                                    <button onClick={onClose} className="text-white text-md font-untitledSans">X</button>
                                </div>
                                <section className="w-full md:w-1/2">
                                    <div className="flex flex-row items-center justify-between w-full text-white mb-[30px] mt-[10px]">
                                        <button className="flex flex-row items-center gap-[15px]">
                                            <img src={`/images/prev-arrow-icon.svg`} alt="Prev icon" className="h-[17px]"/>
                                            <span className="text-white text-xs font-untitledSans">previous</span>
                                        </button>
                                        <button className="flex flex-row items-center gap-[15px]">
                                            <span className="text-white text-xs font-untitledSans">next</span>
                                            <img src={`/images/next-arrow-icon.svg`} alt="Prev icon" className="h-[17px]"/>
                                        </button>
                                    </div>
                                    <div style={{backgroundImage: `url('/images/video-bg.png')`}} className="bg-no-repeat bg-cover bg-center px-[30px] py-[40px]">
                                        <video
                                            ref={videoRef}
                                            onTimeUpdate={handleTimeUpdate}
                                        >
                                            <source src="/video/citta-ideale-gndm_1.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="flex flex-row items-center gap-[10px] mt-[20px]">
                                            <button
                                                onClick={handlePlayPause}
                                                className="cursor-pointer"
                                            >
                                                <img src={isPlaying ? `/images/pause-icon.png` : `/images/play-icon.png`} alt="Play icon" className="h-[17px]"/>
                                            </button>
                                            <div ref={progressRef} className="relative bg-white h-[5px] w-full">
                                                <div
                                                    className="bg-[#ec651f] h-full"
                                                    style={{width: `${progress}%`}}
                                                ></div>
                                                <Draggable
                                                    axis="x"
                                                    bounds="parent"
                                                    position={{x: (progress / 100) * progressRef.current?.offsetWidth || 0, y: 0}}
                                                    onDrag={handleDrag}
                                                >
                                                    <div className="absolute top-[-6px] w-[15px] h-[15px] bg-[#ec651f] rounded-full cursor-pointer"></div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="overflow-hidden w-full  md:w-1/2">
                                    <div className="mb-[20px] h-max flex flex-row justify-end items-end">
                                        <button onClick={onClose} className="hidden md:block text-white text-md font-untitledSans">X</button>
                                    </div>
                                    <div className="max-h-[350px]">
                                        <h2 className="text-white text-md md:text-lg font-untitledSans font-bold">Ritratto di gentildonna (La Muta)</h2>
                                        <span className="text-white text-xs font-untitledSans">#collezioni</span>
                                        <div className="h-[30px]"/>
                                        <p onScroll={checkScroll} ref={scrollableDivRef} className="scrollable-div text-white text-sm font-untitledSans text-justify h-[235px] overflow-y-auto">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem
                                            rerum facilis est et expedita distinctio.
                                            <br/><br/>
                                            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                            Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                            Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                        </p>
                                    </div>
                                    <section className="mt-[20px] flex flex-row items-center justify-between">
                                        {!showScrollUp && <div/>}
                                        {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">Leggi di meno</button>}
                                        {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">Leggi di più</button>}
                                    </section>
                                    <div className="w-full flex flex-col items-center mt-[30px]">
                                        <Button text="Link esterno" onClick={null}/>
                                    </div>
                                </section>
                            </div>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        key="AGModal-root-mobile"
                        className="lg:hidden flex fixed top-0 left-0 w-full h-full bg-[#2f2e2a] justify-center items-center z-[902]"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0, transition: {duration: 0.5}}}
                        onClick={onClose}
                    >
                        <motion.div
                            ref={divRef}
                            key="relative AGModal-content-mobile"
                            className="rounded-lg w-full h-[100dvh] overflow-y-auto"
                            initial={{opacity: 0}}
                            animate={{opacity: 1, transition: {duration: 0.5, delay: 0}}}
                            exit={{opacity: 0, transition: {duration: 0.5}}}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <header className={`px-[20px] ${isScrolled ? 'bg-black' : 'bg-transparent'} h-[84px] lg:h-[60px] absolute top-0 left-0 flex flex-row items-center justify-between w-full gap-[50px] mb-[30px] pr-[20px] z-[999]`}>
                                <div>
                                    <img onClick={goToHome} src="/images/GNDM acronimo orizzontale white.png" alt="Header GNDM Logo" className="max-h-[20px] cursor-pointer lg:hidden"/>
                                    <img onClick={goToHome} src='/images/GNDM logo full.png' alt="Header GNDM Logo" className="max-h-[30px] cursor-pointer hidden lg:block"/>
                                </div>
                                <div className="flex flex-row items-center h-max gap-[50px] mr-[0px]">
                                    <button onClick={openInfo} className="w-[10px] h-[25px] min-w-[10px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src='/images/info-icon.png' alt="Info icon" className="h-full"/></button>
                                    <div className="relative"><HamburgerMenu version="white" onClick={openMenu}/></div>
                                </div>
                            </header>
                            {isTitleScrolled && <div className="z-[999] w-full flex flex-row gap-[10px] justify-between items-center py-[5px] px-[20px] absolute top-[84px] md:top-[60px] left-0 bg-[#e4dbd6]">
                                <p className="text-black text-sm font-untitledSans font-bold">{parseText(data.title)}</p>
                                <button onClick={onClose} className="text-black font-untitledSans cursor-pointer w-full w-max font-bold text-[20px]">X</button>
                            </div>}
                            <div className="flex flex-col lg:flex-row gap-[20px] lg:gap-[50px]">
                                <section className="w-full lg:w-1/2">
                                    <div style={{backgroundImage: `url("${data.backgroundImage}")`}} className="pt-[90px] sm:pt-[80px] bg-no-repeat bg-cover bg-center px-[20px] pb-[40px]">
                                        <button onClick={onClose} className="text-white text-md font-bold font-untitledSans cursor-pointer my-[10px] text-right w-full">X</button>
                                        <h2 className="text-white text-base font-untitledSans font-bold">{parseText(data.title)}</h2>
                                        <span className="text-white text-xxs font-untitledSans">{parseText(data.category)}</span>
                                        <div className="h-[10px]"/>
                                        <video
                                            ref={videoRefMobile}
                                            onTimeUpdate={handleTimeUpdate}
                                            playsInline
                                            poster="/images/mock-video.png"
                                            style={{maxHeight: '250px'}}
                                        >
                                            <source src={data.video} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="flex flex-row items-center gap-[10px] mt-[20px]">
                                            <button
                                                onClick={handlePlayPause}
                                                className="cursor-pointer"
                                            >
                                                <img src={isPlaying ? `/images/pause-icon.png` : `/images/play-icon.png`} alt="Play icon" className="h-[17px]"/>
                                            </button>
                                            <div ref={progressRefMobile} className="relative bg-white h-[5px] w-full" onTouchStart={handleTouchStart}>
                                                <div
                                                    className="bg-[#ec651f] h-full"
                                                    style={{width: `${progress}%`}}
                                                ></div>
                                                <Draggable
                                                    axis="x"
                                                    bounds="parent"
                                                    position={{x: (progress / 100) * progressRefMobile.current?.offsetWidth || 0, y: 0}}
                                                    onDrag={handleDrag}
                                                    defaultClassNameDragging="touch-action-none"
                                                >
                                                    <div className="absolute top-[-6px] w-[15px] h-[15px] bg-[#ec651f] rounded-full cursor-pointer"></div>
                                                </Draggable>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="overflow-hidden w-full px-[20px] lg:w-1/2 pb-[140px]">
                                    <div className="h-full">
                                        <p className="text-white text-sm font-untitledSans text-justify h-full">{parseText(data.content)}</p>
                                    </div>
                                    <section className="mt-[20px] flex flex-row items-center justify-between">
                                        {!showScrollUp && <div/>}
                                        {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_less')}</button>}
                                        {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_more')}</button>}
                                    </section>
                                    {data.externalLink && data.externalLink !== "" && <a href={data.externalLink} target="_blank" className="w-full flex flex-col items-center mt-[20px]">
                                        <Button text={t('vt.global_external_link')} onClick={null}/>
                                    </a>}
                                </section>
                            </div>
                        </motion.div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default AGModalRoot;