import React, {useEffect, useState} from 'react';
import {Canvas} from '@react-three/fiber';
import {useLocation} from "react-router-dom";
import HomePage_old from "./HomePage_old";
import ShapesModalRoot from "./modals/ShapesModalRoot";
import MenuModalRoot from "./modals/MenuModalRoot";
import {AnimatePresence} from "framer-motion";
import RoomFloorMapModalRoot from "./modals/RoomFloorMapModalRoot";
import FloorMapsDrawerRoot from "./modals/FloorMapsDrawerRoot";
import AGDrawerRoot from "./modals/AGDrawerRoot";
import {useDispatch, useSelector} from "react-redux";
import {AGModalId, AGModalOpen, InfoModalOpen, mainSelector, MenuModalOpen, RoomFloorMapsModalOpen, RoomFloorModalOpen, SetSound, SwitchRoom, SwitchSection} from "../store/slices/main.slice";
import PianoTerra1 from "./rooms/PianoTerra/PianoTerra1";
import PianoTerra2 from "./rooms/PianoTerra/PianoTerra2";
import PianoTerra3 from "./rooms/PianoTerra/PianoTerra3";
import PianoTerra4 from "./rooms/PianoTerra/PianoTerra4";
import PianoTerra6 from "./rooms/PianoTerra/PianoTerra6";
import SecondoPiano0 from "./rooms/SecondoPiano/SecondoPiano0";
import SecondoPiano1 from "./rooms/SecondoPiano/SecondoPiano1";
import SecondoPiano2 from "./rooms/SecondoPiano/SecondoPiano2";
import SecondoPiano3 from "./rooms/SecondoPiano/SecondoPiano3";
import SecondoPiano4 from "./rooms/SecondoPiano/SecondoPiano4";
import SecondoPiano5 from "./rooms/SecondoPiano/SecondoPiano5";
import SecondoPiano6 from "./rooms/SecondoPiano/SecondoPiano6";
import SecondoPiano7 from "./rooms/SecondoPiano/SecondoPiano7";
import SecondoPiano8 from "./rooms/SecondoPiano/SecondoPiano8";
import SecondoPiano9 from "./rooms/SecondoPiano/SecondoPiano9";
import SecondoPiano10 from "./rooms/SecondoPiano/SecondoPiano10";
import SecondoPiano11 from "./rooms/SecondoPiano/SecondoPiano11";
import SecondoPiano12 from "./rooms/SecondoPiano/SecondoPiano12";
import SecondoPiano13 from "./rooms/SecondoPiano/SecondoPiano13";
import SecondoPiano14 from "./rooms/SecondoPiano/SecondoPiano14";
import SecondoPiano15 from "./rooms/SecondoPiano/SecondoPiano15";
import Sotterranei1 from "./rooms/Sotterranei/Sotterranei1";
import Sotterranei2 from "./rooms/Sotterranei/Sotterranei2";
import Sotterranei3 from "./rooms/Sotterranei/Sotterranei3";
import Sotterranei4 from "./rooms/Sotterranei/Sotterranei4";
import Sotterranei5 from "./rooms/Sotterranei/Sotterranei5";
import Sotterranei6 from "./rooms/Sotterranei/Sotterranei6";
import Sotterranei7 from "./rooms/Sotterranei/Sotterranei7";
import Sotterranei8 from "./rooms/Sotterranei/Sotterranei8";
import HomePage from "./HomePage";
import { isMobile, isTablet } from 'react-device-detect';
import AGModalRoot from "./modals/AGModalRoot";

const Main = () => {
    const [roomIndex, setRoomIndex] = useState(1);
    const location = useLocation();
    const dispatch: any = useDispatch();
    const { currentRoomIndex, roomFloorModal, roomFloorMapsModal, sound } = useSelector(mainSelector);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const roomIdParam = searchParams.get('room');
        if (searchParams && roomIdParam) {
            setRoomIndex(+roomIdParam)
            dispatch(SwitchRoom(+roomIdParam))
            dispatch(RoomFloorModalOpen(false))
        }
    }, [location]);

    if (location.pathname === "/" && location.search === "") {
        return <HomePage/>
    }

    // Rooms services

    const openInfo = () => {
        dispatch(InfoModalOpen(true))
    }

    const openMenu = () => {
        dispatch(MenuModalOpen(true))
    }

    const openFloorMapModal = () => {
        if(roomFloorModal.open !== true) {
            dispatch(RoomFloorModalOpen(true))
        }
    }

    const closeFloorMapModal = () => {
        dispatch(RoomFloorModalOpen(false))
    }

    const openFloorMapsDrawer = () => {
        dispatch(RoomFloorMapsModalOpen(true))
    }

    const openAGDrawer = (id: any) => {
        dispatch(AGModalOpen(true))
        dispatch(AGModalId(id));
    }

    const handleRoomChange = (room: number) => {
        let url = new URL(window.location as any);
        url.searchParams.set('room', room.toString());
        // eslint-disable-next-line
        history.replaceState(null, '', url);
        dispatch(SwitchRoom(room))
    }

    const goToHome = () => {
        dispatch(SwitchSection(0))
        window.location.href = `/`
    }

    const handleSound = () => {
        dispatch(SetSound(!sound))
    }

    const roomServices = {
        openInfo: openInfo,
        openMenu: openMenu,
        openFloorMapModal: openFloorMapModal,
        openFloorMapsDrawer: openFloorMapsDrawer,
        openAGDrawer: openAGDrawer,
        handleRoomChange: handleRoomChange,
        goToHome: goToHome,
        handleSound: handleSound,
        closeFloorMapModal: closeFloorMapModal
    }

    return (
        <div className="h-[100vh] w-[100vw] overflow-hidden">
            <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center bg-[#1a1a1a] py-[50px]">
                <div className="flex flex-col justify-center items-center gap-[50px]">
                    <img src={'/images/loading.png'} alt="Loader" className="max-h-[100px]"/>
                </div>
            </div>
            <AnimatePresence>
                <ShapesModalRoot children={null}/>
                <RoomFloorMapModalRoot/>
                <FloorMapsDrawerRoot/>
                {isMobile || isTablet ? <AGModalRoot/> : <AGDrawerRoot/>}
            </AnimatePresence>
            <div className="absolute top-0 left-0 h-full w-full z-[99]">
                <Canvas camera={{position: [0, 0, 0.1]}} frameloop="demand">
                    {currentRoomIndex === 1 && <PianoTerra1 imagePath="/images/equirectangulars/Piano terra/Piano_Terra_P1-Piazza.jpg" services={roomServices}/>}
                    {currentRoomIndex === 2 && <PianoTerra2 imagePath="/images/equirectangulars/Piano terra/Piano_Terra_P2-Ingresso.jpg" services={roomServices}/>}
                    {currentRoomIndex === 3 && <PianoTerra3 imagePath="/images/equirectangulars/Piano terra/Piano_Terra_P3-Cortile.jpg" services={roomServices}/>}
                    {currentRoomIndex === 4 && <PianoTerra4 imagePath="/images/equirectangulars/Piano terra/Piano_Terra_P4-Giardino.jpg" services={roomServices}/>}
                    {currentRoomIndex === 5 && <PianoTerra6 imagePath="/images/equirectangulars/Piano terra/Piano_Terra_P6-Cappelletta.jpg" services={roomServices}/>}

                    {currentRoomIndex === 6 && <SecondoPiano1 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P1_SALA-A1.jpg" services={roomServices}/>}
                    {currentRoomIndex === 7 && <SecondoPiano2 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P2-SALA-A2.jpg" services={roomServices}/>}
                    {currentRoomIndex === 8 && <SecondoPiano3 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P3-SALA-A3.jpg" services={roomServices}/>}
                    {currentRoomIndex === 9 && <SecondoPiano4 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P4-SALA-A5.jpg" services={roomServices}/>}
                    {currentRoomIndex === 10 && <SecondoPiano5 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P5-SALA-B.jpg" services={roomServices}/>}
                    {currentRoomIndex === 11 && <SecondoPiano6 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P6-SALA-C.jpg" services={roomServices}/>}
                    {currentRoomIndex === 12 && <SecondoPiano7 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P7-SALA-D3.jpg" services={roomServices}/>}
                    {currentRoomIndex === 13 && <SecondoPiano8 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P8-SALA-E2_A.jpg" services={roomServices}/>}
                    {currentRoomIndex === 14 && <SecondoPiano15 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P9-SALA-E2_B.jpg" services={roomServices}/>}
                    {currentRoomIndex === 15 && <SecondoPiano9 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P10-SALA-E3.jpg" services={roomServices}/>}
                    {currentRoomIndex === 16 && <SecondoPiano10 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P11-SALA-E4.jpg" services={roomServices}/>}
                    {currentRoomIndex === 17 && <SecondoPiano11 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P12-SALA-E6.jpg" services={roomServices}/>}
                    {currentRoomIndex === 18 && <SecondoPiano12 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P13-Torre.jpg" services={roomServices}/>}
                    {currentRoomIndex === 19 && <SecondoPiano13 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P14-SALA-F1.jpg" services={roomServices}/>}
                    {currentRoomIndex === 20 && <SecondoPiano14 imagePath="/images/equirectangulars/Piano secondo/Piano_Secondo_P15-SALA-F2.jpg" services={roomServices}/>}

                    {currentRoomIndex === 21 && <Sotterranei1 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P1_Sala-F1.jpg" services={roomServices}/>}
                    {currentRoomIndex === 22 && <Sotterranei2 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P2_Disimpegno.jpg" services={roomServices}/>}
                    {currentRoomIndex === 23 && <Sotterranei3 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P3_Sala-A2.jpg" services={roomServices}/>}
                    {currentRoomIndex === 24 && <Sotterranei4 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P4_Sala-B1-Edit.jpg" services={roomServices}/>}
                    {currentRoomIndex === 25 && <Sotterranei5 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P5_Sala-D.jpg" services={roomServices}/>}
                    {currentRoomIndex === 26 && <Sotterranei6 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P6_Sala-B4_alto.jpg" services={roomServices}/>}
                    {currentRoomIndex === 27 && <Sotterranei7 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P7_Sala-B4_basso.jpg" services={roomServices}/>}
                    {currentRoomIndex === 28 && <Sotterranei8 imagePath="/images/equirectangulars/Sotterranei/Piano_Seminterrato_P8_Sala-A1.jpg" services={roomServices}/>}
                </Canvas>
            </div>
        </div>
    );
};

export default Main;
