import React, {useEffect, useMemo} from 'react';
import {useLoader, useThree} from '@react-three/fiber';
import { TextureLoader, BackSide, LinearFilter } from 'three';
import {Html, OrbitControls} from '@react-three/drei';
import {mainSelector} from "../../../store/slices/main.slice";
import {Provider, useSelector} from "react-redux";
import * as THREE from 'three';
import Hotspot from "../components/Hotspot";
import HamburgerMenu from "../../layout/HamburgerMenu";
import {store} from "../../../store";
import {useTranslation} from "react-i18next";

interface PanoramaProps {
    imagePath: string;
    services: any;
}
const Sotterranei8: React.FC<PanoramaProps> = ({ imagePath, services}) => {
    const prev = 27
    const next = 1
    const texture = useLoader(TextureLoader, imagePath);
    const { gl } = useThree();
    const { sound, roomFloorMapsModal } = useSelector(mainSelector);
    const { t }: any = useTranslation('translation');

    useEffect(() => {
        services.closeFloorMapModal()
    }, []);


    useMemo(() => {
        gl.setPixelRatio(window.devicePixelRatio);
        texture.minFilter = LinearFilter;
        texture.wrapS = THREE.RepeatWrapping;
        texture.repeat.x = - 1;
    }, [texture, gl]);

    return (
        <>
            <mesh>
                <sphereGeometry args={[500, 60, 40]} />
                <meshBasicMaterial map={texture} side={BackSide} />
            </mesh>
            <OrbitControls enablePan={false} enableZoom={false} rotateSpeed={-0.4} />
            <Hotspot position={[10, 5, -20]} onClick={services.openAGDrawer} />
            <Html position={[0, 0, 0]} center style={{width: "100vw", height: "100dvh", zIndex: 100, display: "flex", flexDirection: "column", justifyContent: "space-between", userSelect: "none"}}>
                <header className="flex flex-col w-full items-center">
                    <div className="flex flex-row items-center justify-between w-full gap-[50px] px-[20px] sm:px-[40px] h-[84px] sm:h-[60px] lg:h-[84px]">
                        <img onClick={services.goToHome} src="/images/GNDM logo full.png" alt="Header GNDM Logo" className="max-h-[30px] cursor-pointer hidden sm:block"/>
                        <img onClick={services.goToHome} src="/images/GNDM acronimo orizzontale white.png" alt="Header GNDM Logo" className="max-h-[15px] cursor-pointer block sm:hidden"/>
                        <div className="flex flex-row items-center h-max gap-[40px]">
                            <button onClick={services.openInfo} className="w-[10px] h-[25px] min-w-[10px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src="/images/info-icon.png" alt="Info icon" className="h-full"/></button>
                            <button onClick={services.handleSound} className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src={sound ? "/images/sound-icon.png" : "/images/sound_off.png"} alt="Sound icon" className="h-full"/></button>
                            <Provider store={store}>
                                <div className="relative ml-[30px] sm:ml-[10px]"><HamburgerMenu version="white" onClick={services.openMenu}/></div>
                            </Provider>
                        </div>
                    </div>
                    <h2 className="text-center text-white text-base lg:text-lg font-untitledSans">
                        {t('vt.virtual_tour_room_title_28')}
                    </h2>
                </header>
                <button onClick={() => services.handleRoomChange(prev)} className="absolute top-1/2 left-4 transform -translate-y-1/2 w-[35px] sm:w-[45px] h-[35px] sm:h-[45px]" style={{visibility: prev ? 'visible' : 'hidden'}}>
                    <img src="/images/prev-room-arrow-icon.png" alt="Prev room icon" className="max-h-[35px] sm:max-h-[45px]"/>
                </button>
                <button onClick={() => services.handleRoomChange(next)} className="absolute top-1/2 right-4 transform -translate-y-1/2 w-[35px] sm:w-[45px] h-[35px] sm:h-[45px]" style={{visibility: next ? 'visible' : 'hidden'}}>
                    <img src="/images/next-room-icon-arrow.png" alt="Next room icon" className="max-h-[35px] sm:max-h-[45px]"/>
                </button>
                <footer className="w-full flex flex-row justify-between items-end p-[20px] lg:p-[40px]">
                    <div className="block lg:hidden">
                        <button onClick={services.openFloorMapModal} className="w-max h-[55px] lg:h-[75px]">
                            <img src="/images/ico_planimetria_mobile.png" alt="Floor icon" className="max-h-[55px] lg:max-h-[75px]"/>
                        </button>
                    </div>
                    <div className="lg:pb-[20px]">
                        <button disabled={roomFloorMapsModal.open} onClick={roomFloorMapsModal.open === false ? services.openFloorMapsDrawer : undefined} className="w-max h-[55px] lg:h-[75px]">
                            <img src="/images/icona_piano_terra.png" alt="AR icon" className="max-h-[55px] lg:max-h-[75px]"/>
                        </button>
                    </div>
                    <div onClick={services.openFloorMapModal} className="hidden lg:flex mb-[-10px] w-max flex-col items-center justify-center cursor-pointer">
                        <p className="text-center text-white text-sm font-untitledSans mb-[5px]">{t('vt.virtual_tour_select_room')}</p>
                        <img src="/images/floor-plans/Sotterranei/8_Piano_Seminterrato_P8_Sala-A1.png" alt="Map" className="max-h-[150px] w-max"/>
                    </div>
                    <button className="hidden lg:block w-max h-[75px]" style={{visibility: "hidden"}}>
                        <img src="/images/AR-icon.png" alt="AR icon" className="max-h-[75px]"/>
                    </button>
                </footer>
            </Html>
        </>
    );
};

export default Sotterranei8;
