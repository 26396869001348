import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ImageSlider = ({ images }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState("right");

    const nextSlide = () => {
        setDirection("left");
        setCurrentIndex((prevIndex) => (prevIndex + 2) % images.length);
    };

    const prevSlide = () => {
        setDirection("right");
        setCurrentIndex((prevIndex) => (prevIndex - 2 + images.length) % images.length);
    };

    // Animation variants
    const variants = {
        enter: (direction: any) => {
            return {
                x: direction === "left" ? 1000 : -1000,
                opacity: 0,
            };
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
        },
        exit: (direction: any) => {
            return {
                zIndex: 0,
                x: direction === "left" ? -1000 : 1000,
                opacity: 0,
            };
        },
    };

    const nextImageIndex = (currentIndex + 1) % images.length;
    const imageIndexesToShow = [currentIndex, nextImageIndex];

    const totalSlides = Math.ceil(images.length / 2);
    const currentSlideNumber = Math.floor(currentIndex / 2) + 1;

    return (
        <div className="flex flex-col justify-center items-center w-full max-w-[400px] lg:max-w-full">
            <div className="flex overflow-hidden gap-[15px] h-[180px] w-full">
                {imageIndexesToShow.map((index) => (
                    <motion.div
                        key={index}
                        className="w-1/2x flex flex-1 h-full"
                        style={{
                            backgroundImage: `url(${images[index]})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                    >
                    </motion.div>
                ))}
            </div>
            <div className="flex flex-row items-center justify-center gap-[30px] mt-[20px]">
                <button className="w-[20px] h-[20px] flex flex-col justify-center items-center" onClick={prevSlide}>
                    <img src="/images/prev-arrow-icon.svg" alt="Prev icon"  className={`h-[15px] w-[auto]`}/>
                </button>
                <div className="flex flex-row items-center justify-center gap-[7px]">
                    {Array.from(Array(totalSlides).keys()).map((item, index) => {
                        return <div key={`dot-${index}`} className={`w-[7px] h-[7px] rounded-full ${index+1 === currentSlideNumber ? 'bg-accent' : 'bg-white'}`}/>
                    })}
                </div>
                <button className="w-[20px] h-[20px] flex flex-col justify-center items-center" onClick={nextSlide}>
                    <img src="/images/next-arrow-icon.svg" alt="Next icon"  className={`h-[15px] w-[auto]`}/>
                </button>
            </div>
        </div>
    );
};

export default ImageSlider;
