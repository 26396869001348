import React, {Suspense, useEffect, useRef, useState, useTransition} from 'react';
import {Canvas, useFrame, useLoader, useThree} from "@react-three/fiber";
import {Environment, CameraControls, useProgress, useGLTF, useHelper, Sky} from "@react-three/drei";
import {AnimatePresence} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, SetAnimation, SetCurrentDistance, SetSound, SwitchSection} from "../store/slices/main.slice";
import {AnimationMixer, LoopOnce, Quaternion, SpotLightHelper, TextureLoader, Vector3} from "three";
import * as THREE from 'three'
import ARModalRoot from "./modals/ARModalRoot";

// Lazy Loading dei componenti per migliorare il caricamento iniziale
const HomeContent = React.lazy(() => import('./HomeContent'));
const PalazzoContent = React.lazy(() => import('./PalazzoContent'));
const ShapesModalRoot = React.lazy(() => import('./modals/ShapesModalRoot'));
const MenuModalRoot = React.lazy(() => import('./modals/MenuModalRoot'));

const HomePage = () => {
    const dispatch: any = useDispatch();
    const {active, progress, loaded, total} = useProgress();
    const [loading, setLoading] = useState<boolean>(false);
    const {section, zoom, sound, animation, currentDistance} = useSelector(mainSelector);
    const [rotationSpeed, setRotationSpeed] = useState(0);
    const [maxPolarAngle, setMaxPolarAngle] = useState(Math.PI);
    const cameraControlRef: any = useRef<CameraControls | null>(null);

    useEffect(() => {
        if (!active && loaded === total && progress === 100) {
            setLoading(false);
        }
    }, [active, progress, loaded, total]);

    // Preload del modello GLB
    /*useGLTF.preload("images/PalazzoDucale_Prova_Textures.glb");
    useGLTF.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')*/

    /*const Scene = React.memo(({animation}: any) => {
        const modelRef = useRef();
        const spotLightRef: any = useRef();
        const spotLight2Ref: any = useRef();
        const spotLight3Ref: any = useRef();
        const spotLight4Ref: any = useRef();
        const spotLight5Ref: any = useRef();
        const totalRotation = -(2 * Math.PI); // Rotazione completa (360 gradi)
        const totalFrames = rotationSpeed; // Totale frame per la rotazione
        const mixer: any = useRef(null);
        const actionRef: any = useRef(null);
        const initialPosition = useRef(new Vector3());
        const initialRotation = useRef(new Quaternion());
        const initialScale = useRef(new Vector3());

        const rotationIncrement = totalRotation / totalFrames;

        /!*useFrame(() => {
            if(rotationSpeed > 0) {
                //@ts-ignore
                modelRef.current.rotation.y += rotationIncrement;
            }
        });*!/

        //@ts-ignore
        let gltf = useGLTF("images/PalazzoDucale_Prova_Textures.glb", true, true);

        useEffect(() => {
            if (gltf.animations.length) {
                mixer.current = new AnimationMixer(gltf.scene);
                actionRef.current = mixer.current.clipAction(gltf.animations[0]);
                actionRef.current.setLoop(LoopOnce, 1);
                actionRef.current.clampWhenFinished = true;

                // Memorizza lo stato iniziale del modello
                initialPosition.current.copy(gltf.scene.position);
                initialRotation.current.copy(gltf.scene.quaternion);
                initialScale.current.copy(gltf.scene.scale);
            }
        }, [gltf]);

        useEffect(() => {
            if (mixer.current) {
                if (animation !== null && animation !== 'reset') {
                    setRotationSpeed(0);

                    // Ripristina lo stato iniziale del modello (posizione, rotazione e scala)
                    gltf.scene.position.copy(initialPosition.current);
                    gltf.scene.quaternion.copy(initialRotation.current);
                    gltf.scene.scale.copy(initialScale.current);

                    // Ripristina la camera esattamente come desiderato
                    cameraControlRef.current?.setLookAt(
                        73, 50, -14,
                        0, 8, -1,
                        true
                    );

                    cameraControlRef.current?.zoomTo(1.5, true);

                    // Ripristina lo stato dell'animazione
                    actionRef.current.reset();
                    actionRef.current.setEffectiveTimeScale(1); // Riproduci in avanti
                    actionRef.current.setEffectiveWeight(1); // Imposta il peso su 1
                    actionRef.current.play();
                }

                if (animation === 'reset') {
                    cameraControlRef.current?.setLookAt(51.61,18.48, 13.56, 20, 15, -1, true);
                    cameraControlRef.current?.zoomTo(1, true);
                    cameraControlRef.current?.dollyTo(120, true);

                    actionRef.current.reset();
                    actionRef.current.paused = false;
                    actionRef.current.time = actionRef.current.getClip().duration; // Imposta alla fine dell'animazione
                    actionRef.current.setEffectiveTimeScale(-1); // Riproduci al contrario
                    actionRef.current.setEffectiveWeight(1); // Imposta il peso su 1
                    actionRef.current.play();

                    setTimeout(() => {
                        setRotationSpeed(0);
                        dispatch(SetAnimation(null));
                    }, actionRef.current.getClip().duration * 1000);
                }
            }
        }, [animation, gltf]);

        useFrame((state, delta) => {
            if (mixer.current) mixer.current.update(delta);
        });

        useEffect(() => {
            if (spotLight5Ref.current) {
                // Sposta il target della luce un po' a destra sull'asse X
                spotLight5Ref.current.target.position.set(-50, 30, -20); // Cambia il valore X per ruotare la luce a destra
                spotLight5Ref.current.target.updateMatrixWorld(); // Assicurati di aggiornare il target
            }
        }, []);

        useEffect(() => {
            if (spotLight3Ref.current) {
                // Sposta il target della luce un po' a destra sull'asse X
                spotLight3Ref.current.target.position.set(120, 80, 120); // Cambia il valore X per ruotare la luce a destra
                spotLight3Ref.current.target.updateMatrixWorld(); // Assicurati di aggiornare il target
            }
        }, []);

        // Handle distance for zoom
        useFrame(() => {
            if (cameraControlRef.current && animation === null) {
                dispatch(SetCurrentDistance(cameraControlRef.current.distance))
            }
        });

        return <>
            <primitive ref={modelRef} object={gltf.scene} scale={1} castShadow={true} receiveShadow={true}/>
            {animation === null && <>
                <spotLight
                    ref={spotLightRef}
                    position={[40, 20, -50]}
                    angle={Math.PI / 6}
                    penumbra={0.5}
                    intensity={40000}
                    distance={100}
                    target={modelRef.current}
                    color="orange"
                    castShadow
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                />
                <spotLight
                    ref={spotLight2Ref}
                    position={[40, 20, 40]}
                    angle={Math.PI}
                    penumbra={0.5}
                    intensity={40000}
                    distance={100}
                    target={modelRef.current}
                    color="orange"
                    castShadow
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                />
                <spotLight
                    ref={spotLight4Ref}
                    position={[-60, 10, 100]}
                    angle={Math.PI / 6}
                    penumbra={0.5}
                    intensity={300000}
                    distance={80}
                    target={modelRef.current}
                    color="#e1c6a7"
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                />
                <spotLight
                    ref={spotLight5Ref}
                    position={[-60, -10, 30]}
                    angle={Math.PI / 6}
                    penumbra={0.5}
                    intensity={300000}
                    distance={80}
                    target={modelRef.current}
                    color="white"
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                />
                <spotLight
                    ref={spotLight3Ref}
                    position={[-90, -10, 10]}
                    angle={Math.PI / 6}
                    penumbra={0.5}
                    intensity={200000}
                    distance={70}
                    target={modelRef.current}
                    color="white"
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                />
            </>}
        </>;
    });*/

    useEffect(() => {
        if (!loading && section === 0) {
            initialPositioning();
        }
        if (!loading && section === 1) {
            changePosition();
        }
    }, [loading, section]);

    useEffect(() => {
        if (cameraControlRef.current) {
            const zoomLevel = 1 + (zoom / 100);
            //@ts-ignore
            cameraControlRef.current.zoomTo(zoomLevel, true);
        }
    }, [zoom]);

    const initialPositioning = () => {
        //@ts-ignore
        setMaxPolarAngle(Math.PI);
        if (cameraControlRef.current) cameraControlRef.current.smoothTime = 1;
        cameraControlRef.current?.setLookAt(32.93, 19.12, 11.33, 0, 8, -1, true);
        cameraControlRef.current?.zoomTo(1.5, true);
    }

    const changePosition = () => {
        setMaxPolarAngle(Math.PI / 2.2);
        //@ts-ignore
        dispatch(SwitchSection(1));
        if (cameraControlRef.current) cameraControlRef.current.smoothTime = 1;
        cameraControlRef.current?.setLookAt(51.61, 18.48, 13.56, 20, 15, -1, true);
        cameraControlRef.current?.zoomTo(1, true);
        cameraControlRef.current?.dollyTo(120, true);
    }

    const handleZoom = (constant: number) => {
        cameraControlRef.current?.dollyTo((constant / 100) * 190 + 10, true);
    }

    const handleSound = () => {
        dispatch(SetSound(!sound));
    }

    // Custom loader ottimizzato
    const CustomLoader = () => {
        return (
            <div className="w-[100vw] h-[100vh] flex flex-col justify-between items-center bg-[#1a1a1a] py-0 sm:py-[50px] pt-safe sm:pt-safe pb-safe sm:pb-0">
                <img src={'/images/GNDM acronimo orizzontale white.png'} alt="Header GNDM Logo" className="max-h-[20px]"/>
                <div className="flex flex-col justify-center items-center gap-[30px] lg:gap-[50px]">
                    <img src={'/images/loading.png'} alt="Loader" className="max-h-[60px] lg:max-h-[100px]"/>
                    <span className="text-[#717171] text-[20px] font-untitledSans">{progress.toFixed()}%</span>
                </div>
                <div className="pb-[50px]">
                    <button onClick={handleSound} className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src={sound ? "/images/sound-icon.png" : "/images/sound_off.png"} alt="Sound icon" className="h-full"/></button>
                </div>
            </div>
        );
    }

    const [bgTexture, setBgTexture] = useState(null);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            const loader = new TextureLoader();
            loader.load("images/panorama_drone_v3.jpg", (texture: any) => {
                setBgTexture(texture);
            });
        });
    }, []);

    return (
        <div className="h-[100vh] w-[100vw] overflow-hidden">
            <AnimatePresence>
                <Suspense fallback={<CustomLoader/>}>
                    {!loading && section === 0 && <HomeContent key="home" changePosition={changePosition}/>}
                    {!loading && section === 1 && <PalazzoContent key="palazzo" handleZoom={handleZoom} currentDistance={currentDistance} />}
                    <ShapesModalRoot />
                    <ARModalRoot/>
                </Suspense>
            </AnimatePresence>
            <div className="absolute top-0 left-0 h-full w-full">
                {loading && <CustomLoader/>}
                <Canvas camera={{position: [0, 0, 0]}} frameloop="demand">
                    <Suspense fallback={null}>
                        <CameraControls ref={cameraControlRef} maxPolarAngle={maxPolarAngle} maxDistance={200} minDistance={10}/>
                        {/*<Scene animation={animation}/>*/}
                        {/*<CameraPositionLogger event="mousedown"/>*/}
                        <ambientLight intensity={3}/>
                        {/*<Environment
                            files="./images/panorama_drone_v3.hdr"
                            background
                            blur={0}
                        />*/}
                        <mesh position={[0, 0, 0]}>
                            <sphereGeometry args={[500, 60, 40]}/>
                            <meshBasicMaterial map={bgTexture} side={2}/>
                        </mesh>
                    </Suspense>
                </Canvas>
            </div>
        </div>
    );
};

export default HomePage;
