import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from "react-redux";
import {MenuModalOpen, mainSelector, SwitchSection, InfoModalOpen} from "../../store/slices/main.slice";
import Header from "../layout/Header";
import {useTranslation} from "react-i18next";

const MenuModalRoot = () => {
    const dispatch: any = useDispatch();
    const { menuModal, infoModal } = useSelector(mainSelector);
    const [selectedPolicy, setSelectedPolicy] = useState<'privacy' | 'legal'>('privacy')

    const { i18n, t }: any = useTranslation();

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
    }, []);

    const onClose = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
    }

    const goToPalazzo = () => {
        dispatch(SwitchSection(1))
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
        window.location.href = `/`
    }

    const goToVirtualTour = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
        window.location.href = `?room=${1}`;
    }

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);

    const checkScroll = () => {
        const div = scrollableDivRef.current;
        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        const div = scrollableDivRef.current;
        if (div) {
            const preventScroll = (e: any) => e.preventDefault();
            //@ts-ignore
            div.addEventListener('wheel', preventScroll, { passive: false });

            return () => {
                //@ts-ignore
                div.removeEventListener('wheel', preventScroll);
            };
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, infoModal, menuModal]);

    const scrollDown = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const scrollUp = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };

    const Divider = () => {
        return (
            <div className="w-[90%] h-[1px] bg-accent my-[20px]"/>
        )
    }

    if(menuModal.open === true || infoModal.open === true) {
        return (
            <AnimatePresence>
                {menuModal.open === true && <motion.div
                    key="shapeModal-root"
                    className="fixed top-0 left-0 w-full h-full justify-center items-center z-[991] bg-[#302e2b]"
                    initial={{height: 0}}
                    animate={{height: '100vh', transition: {duration: 0.25, delay: 0}}}
                    exit={{height: 0, transition: {duration: 0.25, delay: 0.10}}}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="w-[100vw] h-[100dvh] bg-[#302e2b] flex flex-col items-center justify-center"
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 0.10, delay: 0.25}}}
                        exit={{opacity: 0, transition: {duration: 0.10, delay: 0}}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <header className="absolute top-0 right-0 flex flex-row justify-between items-center w-full gap-[50px] h-[84px] sm:h-[60px] md:h-[84px]">
                            <Header/>
                        </header>
                        <div className="flex flex-col sm:gap-[5px] lg:gap-[30px] w-max h-full items-center justify-center">
                            <h2 onClick={goToPalazzo} className="text-white text-2md sm:text-md lg:text-xl font-untitledSans uppercase px-[100px] cursor-pointer">{t('vt.main_menu_palazzo_3d')}</h2>
                            <Divider/>
                            <h2 onClick={goToVirtualTour} className="text-white text-2md sm:text-md lg:text-xl font-untitledSans uppercase cursor-pointer">{t('vt.main_menu_virtual_tour')}</h2>
                        </div>
                        <motion.footer
                            key="home-content-footer"
                            className="flex flex-row items-center h-max gap-[10px] pb-[30px]"
                            initial={{opacity: 1}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0, transition: {duration: 1}}}
                        >
                            <button onClick={() => changeLanguage('it')} className={`text-white text-xs md:text-base font-untitledSans hover:underline ${i18n.language === 'it' && 'underline'}`}>IT</button>
                            <div className="bg-black w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                            <button onClick={() => changeLanguage('en')} className={`text-white text-xs md:text-base font-untitledSans hover:underline ${i18n.language === 'en' && 'underline'}`}>EN</button>
                            <div className="bg-black w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                            <button onClick={() => changeLanguage('de')} className={`text-white text-xs md:text-base font-untitledSans hover:underline ${i18n.language === 'de' && 'underline'}`}>DE</button>
                            <div className="bg-black w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                            <button onClick={() => changeLanguage('fr')} className={`text-white text-xs md:text-base font-untitledSans hover:underline ${i18n.language === 'fr' && 'underline'}`}>FR</button>
                        </motion.footer>
                    </motion.div>
                </motion.div>}
                {infoModal.open && (
                    <motion.div
                        key="shapeModal-root-info"
                        className="fixed top-0 left-0 w-full h-0 bg-[#302e2b] flex justify-center items-center z-[991] overflow-y-scroll lg:overflow-hidden"
                        initial={{height: 0}}
                        animate={{height: '100vh', transition: {duration: 0.25, delay: 0}}}
                        exit={{height: 0, transition: {duration: 0.25, delay: 0.10}}}
                    >
                        <header className="absolute top-0 right-0 flex flex-row justify-between items-center w-full gap-[50px] h-[84px] sm:h-[60px] lg:h-[84px] z-[999]">
                            <Header/>
                        </header>
                        <motion.div
                            key="shapeModal-content-info"
                            className="relative h-[100dvh] flex flex-col lg:items-center lg:justify-center p-[20px] pt-[60px] lg:p-[60px] w-[90vw] lg:w-[70vw]"
                            initial={{opacity: 0}}
                            animate={{opacity: 1, transition: {duration: 0.10, delay: 0.25}}}
                            exit={{opacity: 0, transition: {duration: 0.10, delay: 0}}}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex flex-col lg:flex-row gap-[30px]">
                                <section className="w-full lg:w-[50%]">
                                    <h2 className="text-white text-base lg:text-md font-untitledSans font-medium mb-[15px]">{t('vt.info_menu_links')}</h2>
                                    <a href="https://gndm.it/" target="_blank" className="text-white text-xs lg:text-sm font-untitledSans underline">gallerianazionalemarche.it</a>
                                    <div className="mt-[5px]">
                                        <span className="text-white text-xs lg:text-sm font-untitledSans">{t('vt.info_menu_condividi')}:</span>
                                        <div className="flex flex-row items-center gap-[20px] mt-[10px]">
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/instragram-icon.png" alt="Instagram icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/whatsapp-icon.png" alt="Whatsapp icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/facebook-icon.png" alt="Facebook icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/x-icon.png" alt="X icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/linkdin-icon.png" alt="Linkdin icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                        </div>
                                    </div>
                                    <Divider/>
                                    <h2 className="text-white text-base lg:text-md font-untitledSans font-medium mb-[15px]">{t('vt.info_menu_credits_title')}</h2>
                                    <p className="text-white text-xs lg:text-sm font-untitledSans mb-[5px]">{t('vt.info_menu_credits_text')}: <a href="https://www.taleormade.com/" target="_blank" className="underline">taleormade.com</a></p>
                                    <p className="text-white text-xs lg:text-sm font-untitledSans">{t('vt.info_menu_pm_text')}</p>
                                    <Divider/>
                                    <p onClick={() => setSelectedPolicy('privacy')} className="text-white text-xs lg:text-sm font-untitledSans mb-[5px] underline cursor-pointer">{t('vt.info_menu_privacy')}</p>
                                    <p onClick={() => setSelectedPolicy('legal')} className="text-white text-xs lg:text-sm font-untitledSans underline cursor-pointer">{t('vt.info_menu_legal')}</p>
                                </section>
                                <section className="overflow-hidden w-full lg:w-[50%] pb-safe lg:pb-0">
                                    <div className="max-h-[450px]">
                                        <h2 className="text-white text-base lg:text-md font-untitledSans font-medium mb-[10px]">{selectedPolicy === 'privacy' ? t('vt.info_menu_privacy_title') : t('vt.info_menu_legal_title')}</h2>
                                        <p onScroll={checkScroll} ref={scrollableDivRef} className="scrollable-div text-white text-xs lg:text-sm font-untitledSans text-justify h-[380px] overflow-y-auto">{selectedPolicy === 'privacy' ? t('vt.info_menu_privacy_text') : t('vt.info_menu_legal_text')}
                                        </p>
                                    </div>
                                    <section className="mt-[20px] flex flex-row items-center justify-between mb-[40px] md:mb-0">
                                        {!showScrollUp && <div/>}
                                        {showScrollUp && <button onClick={scrollUp} className="text-white text-xxs lg:text-xs font-untitledSans font-bold h-[2opx] cursor-pointer">{t('vt.global_read_less')}</button>}
                                        {showScrollDown && <button onClick={scrollDown} className="text-white text-xxs lg:text-xs font-untitledSans font-bold h-[2opx] cursor-pointer">{t('vt.global_read_more')}</button>}
                                    </section>
                                </section>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }

    return null
};

export default MenuModalRoot;
