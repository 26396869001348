import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import ImageSider from "../layout/ImageSider";
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, SetAnimation, ShapeModalId, ShapeModalOpen} from "../../store/slices/main.slice";
import {apiService} from "../../services/api.service";
import {useTranslation} from "react-i18next";
import { isMobile, isTablet } from 'react-device-detect';

const ShapesModalRoot = ({ children }: any) => {
    const dispatch: any = useDispatch();
    const { shapeModal } = useSelector(mainSelector);
    const [data, setData] = useState<any>(null)
    const { i18n, t }: any = useTranslation('translation');
    const [isFloorImageOpen, setIsFloorImageOpen] = useState(false)

    useEffect(() => {
        if(shapeModal.open && shapeModal.id) {
            apiService.getPopupPalazzo(shapeModal.id)
                .then((res) => {
                    setData(res.data)
                })
        }
    }, [shapeModal.open]);

    useEffect(() => {
        dispatch(SetAnimation('reset'))
        dispatch(ShapeModalOpen(false))
        dispatch(ShapeModalId(null))
    }, []);

    const onClose = () => {
        dispatch(SetAnimation('reset'))
        dispatch(ShapeModalOpen(false))
        dispatch(ShapeModalId(null))
    }

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);

    const checkScroll = () => {
        const div = scrollableDivRef.current;
        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        const div = scrollableDivRef.current;
        if (div) {
            const preventScroll = (e: any) => e.preventDefault();
            //@ts-ignore
            div.addEventListener('wheel', preventScroll, { passive: false });

            return () => {
                //@ts-ignore
                div.removeEventListener('wheel', preventScroll);
            };
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, shapeModal]);

    const scrollDown = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const scrollUp = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };


    return (
        <AnimatePresence>
            {shapeModal?.open && data && (
                <motion.div
                    key="shapeModal-root"
                    className="fixed top-0 left-0 w-full h-full bg-black/85 lg:bg-black/65 flex sm:justify-center lg:items-center z-[100]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1, delay: 1 }}}
                    exit={{ opacity: 0, transition: { duration: 0.5, delay: 0 } }}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="pt-safe sm:pt-[20px] px-[20px] lg:p-6 rounded-lg w-full sm:w-[80vw] lg:w-[70vw]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 0.5 }}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col sm:flex-row gap-[30px]">
                            {isFloorImageOpen && <section className="h-full w-screen hidden sm:flex lg:hidden flex-col items-center">
                                <div className="w-full flex items-center justify-end pb-[20px]">
                                    <button onClick={() => setIsFloorImageOpen(false)} className="text-white text-md font-untitledSans leading-[15px]">X</button>
                                </div>
                                <div className={`h-full w-[auto] flex flex-col items-center justify-center`}>
                                    <img src={data.floorImage} alt="Plan map" className={`h-full max-h-[220px] w-[auto]`}/>
                                </div>
                            </section>}
                            {!isFloorImageOpen && <section className="pt-0 lg:pt-[80px] hidden sm:block pb-safe lg:pb-0 mt-0 sm:mt-[-35px] lg:mt-0">
                                <div className={`w-[200px] sm:w-[250px] lg:w-[400px] h-[90px] lg:h-[180px] flex flex-col items-center justify-center`}>
                                    <img src={data.floorImage} alt="Plan map" className={`hidden lg:block h-full w-[auto]`}/>
                                </div>
                                <ImageSider images={data.gallery}/>
                                <div className="w-full hidden sm:flex lg:hidden flex-col items-center justify-center pb-safe pt-[20px]">
                                    <button className="w-[25px] h-[30px] cursor-pointer" onClick={() => setIsFloorImageOpen(true)}>
                                        <img src="/images/location-dot-solid.svg" alt="Map pin icon" className="max-h-[30px]"/>
                                    </button>
                                </div>
                            </section>}
                            {isFloorImageOpen && <section className="w-full h-full flex sm:hidden flex-col items-center pt-[150px]">
                                <>
                                    <div className="w-full flex items-center justify-end pb-[50px]">
                                        <button onClick={() => setIsFloorImageOpen(false)} className="text-white text-md font-untitledSans leading-[15px]">X</button>
                                    </div>
                                    <div className={`w-full h-[auto] flex flex-col items-center justify-center`}>
                                        <img src={data.floorImage} alt="Plan map" className={`w-full h-[auto]`}/>
                                    </div>
                                </>
                            </section>}
                            {!isFloorImageOpen && <section className="overflow-y-auto lg:overflow-hidden h-[100vh] lg:h-auto pb-safe lg:pb-0">
                                <div className="mb-[20px] h-max w-full flex flex-row justify-between items-end">
                                    <div className="hidden sm:flex lg:hidden w-[10px]"/>
                                    <div className={`w-[38px] lg:w-[60px] h-[30px] lg:h-[50px] flex sm:hidden lg:flex flex-col items-center justify-center cursor-pointer ml-[-5px]`}>
                                        <img src={data.icon} alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[30px] lg:max-h-[45px] h-full w-[auto]`}/>
                                    </div>
                                    <button onClick={onClose} className="text-white text-base font-untitledSans">X</button>
                                </div>
                                <div className="lg:max-h-[400px]">
                                    <div className="w-full flex flex-row items-center gap-[15px]">
                                        <div className={`w-[38px] lg:w-[60px] h-[30px] lg:h-[50px] hidden sm:flex lg:hidden flex-col items-center justify-center cursor-pointer ml-[-5px]`}>
                                            <img src={data.icon} alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[30px] lg:max-h-[45px] h-full w-[auto]`}/>
                                        </div>
                                        <h2 className="text-white text-md lg:text-xl font-untitledSans">lorem ipsum {data.title[i18n.language]}</h2>
                                    </div>
                                    {!isMobile && !isTablet &&
                                        <p onScroll={checkScroll} ref={scrollableDivRef} className="hidden lg:block lg:scrollable-div text-white text-xs lg:text-sm font-untitledSans text-justify lg:h-[285px] lg:overflow-y-auto">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                                            making
                                            it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its
                                            layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
                                            purpose (injected humour and the like).{data.content[i18n.language]}</p>}
                                    {(isMobile || isTablet) &&
                                        <p onScroll={checkScroll} ref={scrollableDivRef} className="block lg:hidden scrollable-div text-white text-xs font-untitledSans text-justify max-h-[185px] sm:max-h-[120px] overflow-y-auto">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like
                                            readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The
                                            point
                                            of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected
                                            humour and the like).{data.content[i18n.language]}</p>}
                                </div>
                                <section className="mt-[20px] flex flex-row items-center justify-between">
                                    {!showScrollUp && <div/>}
                                    {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_less')}</button>}
                                    {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_more')}</button>}
                                </section>
                                <section className="pt-0 lg:pt-[80px] flex flex-col items-center sm:hidden mt-[40px] pb-[30px] px-[30px]">
                                    <div className={`w-full max-w-[400px] h-[150px] sm:h-[200px] flex-col items-center justify-center hidden lg:flex`}>
                                        <img src="/images/shapes-content/shape-1/mocked-map.png" alt="Plan map" className={`h-[auto] sm:h-full w-full sm:w-[auto]`}/>
                                    </div>
                                    <ImageSider images={data.gallery}/>
                                </section>
                                <div className="w-full flex sm:hidden flex-col items-center justify-center pb-safe">
                                    <button className="w-[25px] h-[30px] cursor-pointer" onClick={() => setIsFloorImageOpen(true)}>
                                        <img src="/images/location-dot-solid.svg" alt="Map pin icon" className="max-h-[30px]"/>
                                    </button>
                                </div>
                            </section>}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ShapesModalRoot;
