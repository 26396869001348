import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";
import {mainSelector, RoomFloorMapsModalOpen, SwitchRoom} from "../../store/slices/main.slice";
import {useTranslation} from "react-i18next";

const FloorMapsDrawerRoot = () => {
    const drawerRef = useRef(null);
    const dispatch: any = useDispatch();
    const { roomFloorMapsModal, currentRoomIndex } = useSelector(mainSelector);
    const { t }: any = useTranslation('translation');

    useEffect(() => {
        dispatch(RoomFloorMapsModalOpen(false));
    }, []);

    const onClose = () => {
        //@ts-ignore
        dispatch(RoomFloorMapsModalOpen(false));
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleRoomChange = (room: number) => {
        let url = new URL(window.location as any);
        url.searchParams.set('room', room.toString());
        // eslint-disable-next-line
        history.replaceState(null, '', url);
        dispatch(SwitchRoom(room))
    }

    const getFloorIndex = () => {
        switch (currentRoomIndex) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return 0
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
                return 2
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
            case 28:
                return -1
        }
    }
    const handleFloorClick = (floorIndex: number) => {
        switch (floorIndex) {
            case -1:
                handleRoomChange(21);
                onClose();
                break;
            case 0:
                handleRoomChange(1);
                onClose();
                break;
            case 1:
                return
            case 2:
                handleRoomChange(6);
                onClose();
                break;
        }
    }

    return (
        <AnimatePresence>
            {roomFloorMapsModal.open && (
                <motion.div
                    ref={drawerRef}
                    key="floor-maps-drawer--root"
                    className="fixed top-0 right-0 bottom-0 w-[100vw] lg:w-[500px] bg-[#302e2b] z-[100] h-[100vh overflow-y-auto"
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '100%' }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="p-[20px] lg:p-[40px] flex flex-col h-full">
                        {/* Bottone di chiusura */}
                        <button
                            onClick={onClose}
                            className="w-max h-max max-w-[45px] max-h-[45px] flex sm:hidden lg:flex items-center justify-center mb-[40px]"
                        >
                            <img
                                src="/images/close-drawer-icon.png"
                                alt="Close icon"
                                className="max-h-[20px] w-auto"
                            />
                        </button>

                        {/* Titolo */}
                        <div className="w-full flex flex-row items-center justify-between">
                            <h4 className="text-white text-xs lg:text-base font-untitledSans w-full mb-[20px] lg:mb-[40px]">
                                {t('vt.virtual_tour_floor_maps_drower')}:
                            </h4>
                            <button
                                onClick={onClose}
                                className="w-max h-max max-w-[45px] max-h-[45px] hidden sm:flex lg:hidden items-center justify-center"
                            >
                                <img
                                    src="/images/close-drawer-icon.png"
                                    alt="Close icon"
                                    className="max-h-[20px] w-auto"
                                />
                            </button>
                        </div>

                        {/* Contenitore delle mappe */}
                        <div
                            id="maps-container"
                            className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-4 lg:flex h-full w-full overflow-hidden"
                        >
                            {/* Mappa 1 */}
                            {getFloorIndex() !== 2 && (
                                <div
                                    className="flex flex-grow min-h-0 items-center justify-center cursor-pointer"
                                    onClick={() => handleFloorClick(2)}
                                >
                                    <img
                                        src="/images/floor-navigation/planim_piano2.png"
                                        alt="Map"
                                        className="h-full max-h-full w-auto object-contain"
                                    />
                                </div>
                            )}
                            {getFloorIndex() === 2 && (
                                <div
                                    className="flex flex-grow min-h-0 items-center justify-center cursor-pointer"
                                    onClick={() => handleFloorClick(2)}
                                >
                                    <img
                                        src="/images/floor-navigation/planim_piano2_on.png"
                                        alt="Map"
                                        className="h-full max-h-full w-auto object-contain"
                                    />
                                </div>
                            )}

                            {/* Mappa 2 */}
                            <div className="flex flex-grow min-h-0 items-center justify-center">
                                <img
                                    src="/images/floor-navigation/planim_nav_piano1.png"
                                    alt="Map"
                                    className="h-full max-h-full w-auto object-contain"
                                />
                            </div>

                            {/* Mappa 3 */}
                            {getFloorIndex() !== 0 && (
                                <div
                                    className="flex flex-grow min-h-0 items-center justify-center cursor-pointer"
                                    onClick={() => handleFloorClick(0)}
                                >
                                    <img
                                        src="/images/floor-navigation/planim_piano0.png"
                                        alt="Map"
                                        className="h-full max-h-full w-auto object-contain"
                                    />
                                </div>
                            )}
                            {getFloorIndex() === 0 && (
                                <div
                                    className="flex flex-grow min-h-0 items-center justify-center cursor-pointer"
                                    onClick={() => handleFloorClick(0)}
                                >
                                    <img
                                        src="/images/floor-navigation/planim_piano0_on.png"
                                        alt="Map"
                                        className="h-full max-h-full w-auto object-contain"
                                    />
                                </div>
                            )}

                            {/* Mappa 4 */}
                            {getFloorIndex() !== -1 && (
                                <div
                                    className="flex flex-grow min-h-0 items-center justify-center cursor-pointer"
                                    onClick={() => handleFloorClick(-1)}
                                >
                                    <img
                                        src="/images/floor-navigation/planim_piano_sott.png"
                                        alt="Map"
                                        className="h-full max-h-full w-auto object-contain"
                                    />
                                </div>
                            )}
                            {getFloorIndex() === -1 && (
                                <div
                                    className="flex flex-grow min-h-0 items-center justify-center cursor-pointer"
                                    onClick={() => handleFloorClick(-1)}
                                >
                                    <img
                                        src="/images/floor-navigation/planim_piano_sott_on.png"
                                        alt="Map"
                                        className="h-full max-h-full w-auto object-contain"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );


};

export default FloorMapsDrawerRoot;
