import {useRef} from "react";
import {useFrame} from "@react-three/fiber";
import {Html} from "@react-three/drei";
import * as THREE from 'three';
import {useSelector} from "react-redux";
import {mainSelector} from "../../../store/slices/main.slice";

const Hotspot: React.FC<any> = ({ position, onClick }: any) => {
    const hotspotRef: any = useRef();
    const { AGModal } = useSelector(mainSelector);

    useFrame(() => {
        //@ts-ignore
        hotspotRef.current.lookAt(new THREE.Vector3(0, 0, 0));
    });

    return (
        <group>
            <mesh
                ref={hotspotRef}
                position={position}
                onClick={AGModal.open === false ? onClick : undefined}
            >
                <sphereGeometry args={[1, 16, 16]} />
                <meshBasicMaterial visible={false} />
            </mesh>
            <Html wrapperClass="custom-hotspot-wrapper" position={position} style={{ transform: 'translate(-50%, -50%)', left: '50%', top: '50%', zIndex: 999999999 }}>
                <div style={{ width: '165px', height: '165px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="/images/hotspot.png" alt="Hotspot" onClick={AGModal.open === false ? onClick : undefined} style={{ maxWidth: '60%', maxHeight: '60%', objectFit: 'contain' }} />
                </div>
            </Html>
        </group>
    );
}

export default Hotspot;
