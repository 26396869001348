import React  from 'react';
import {useDispatch, useSelector} from "react-redux";
import {InfoModalOpen, mainSelector, MenuModalOpen, SetSound, SwitchSection} from "../../store/slices/main.slice";
import HamburgerMenu from "./HamburgerMenu";

const Header = ({version = 'light'}: any) => {
    const dispatch: any = useDispatch();
    const { sound } = useSelector(mainSelector);

    const openInfo = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(true))
    }

    const openMenu = () => {
        dispatch(MenuModalOpen(true))
    }

    const goToHome = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
        dispatch(SwitchSection(0))
        window.location.href = `/`
    }

    const handleSound = () => {
        dispatch(SetSound(!sound))
    }

    return (
        <header className="flex flex-row items-center justify-between w-full gap-[50px] px-[20px] sm:px-[40px] h-[84px] sm:h-[60px] lg:h-[84px] pt-0 sm:pt-[20px] lg:pt-0">
            <div>
                <img onClick={goToHome} src={version === 'light' ? '/images/GNDM acronimo orizzontale white.png' : '/images/GNDM acronimo orizzontale white.png'} alt="Header GNDM Logo" className="max-h-[20px] cursor-pointer lg:hidden"/>
                <img onClick={goToHome} src={version === 'light' ? '/images/GNDM logo full.png' : '/images/GNDM logo full black.png'} alt="Header GNDM Logo" className="max-h-[30px] cursor-pointer hidden lg:block"/>
            </div>
            <div className="flex flex-row items-center h-max gap-[50px]">
                <button onClick={openInfo} className="mr-[-10px] w-[20px] h-[25px] min-w-[10px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src={version === 'light' ? '/images/info-icon.png' : '/images/info-icon-black.png'} alt="Info icon" className="h-full"/></button>
                <button onClick={handleSound} className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src={sound ? "/images/sound-icon.png" : "/images/sound_off.png"} alt="Sound icon" className="h-full"/></button>
                <div className="relative ml-[20px] sm:ml-[10px]"><HamburgerMenu version="white" onClick={openMenu}/></div>
            </div>
        </header>
    );
};

export default Header;
