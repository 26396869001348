import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from "react-redux";
import {AGModalId, AGModalOpen, mainSelector, SetSound} from "../../store/slices/main.slice";
import { isMobile, isTablet } from 'react-device-detect';
import Draggable from 'react-draggable';
import {useTranslation} from "react-i18next";
import {apiService} from "../../services/api.service";

const AGDrawerRoot = () => {
    const drawerRef = useRef(null);
    const dispatch: any = useDispatch();
    const {AGModal, sound} = useSelector(mainSelector);
    const [data, setData] = useState<any>(null)
    const { i18n, t }: any = useTranslation('translation');

    useEffect(() => {
        if(AGModal.open && AGModal.id) {
            apiService.getAudioguida(AGModal.id)
                .then((res) => {
                    setData(res.data)
                })
        }
    }, [AGModal.open]);

    const parseText = (text: any) => {
        try {
            // Controlla se è un JSON stringificato
            const parsed = JSON.parse(text);
            // Se il parsing riesce ed è un oggetto, ritorna il risultato
            if (typeof parsed === 'object' && parsed !== null) {
                return parsed[i18n.language];
            }
        } catch (error) {
            // Non fare nulla, non è un JSON valido
        }

        // Ritorna il valore originale se non è un JSON
        return text[i18n.language];
    };

    useEffect(() => {
        dispatch(AGModalOpen(false));
        dispatch(AGModalId(null));
    }, []);

    const onClose = () => {
        //@ts-ignore
        dispatch(AGModalOpen(false));
        dispatch(AGModalId(null));
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);
    const scrollableDivRefMobile = useRef(null);

    const checkScroll = () => {
        let div: any

        if(isMobile || isTablet) {
            div = scrollableDivRefMobile.current;
        } else {
            div = scrollableDivRef.current;
        }

        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        if(isMobile || isTablet) {
            const div = scrollableDivRef.current;
            if (div) {
                const preventScroll = (e: any) => e.preventDefault();
                //@ts-ignore
                div.addEventListener('wheel', preventScroll, { passive: false });

                return () => {
                    //@ts-ignore
                    div.removeEventListener('wheel', preventScroll);
                };
            }
        } else {
            const div = scrollableDivRef.current;
            if (div) {
                const preventScroll = (e: any) => e.preventDefault();
                //@ts-ignore
                div.addEventListener('wheel', preventScroll, { passive: false });

                return () => {
                    //@ts-ignore
                    div.removeEventListener('wheel', preventScroll);
                };
            }
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, scrollableDivRefMobile, AGModal]);

    const scrollDown = () => {
        if(isMobile || isTablet) {
            if (scrollableDivRefMobile.current) {
                //@ts-ignore
                scrollableDivRefMobile.current.scrollBy({ top: 100, behavior: 'smooth' });
            }
        } else {
            if (scrollableDivRef.current) {
                //@ts-ignore
                scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
            }
        }
    };

    const scrollUp = () => {
        if(isMobile || isTablet) {
            if (scrollableDivRefMobile.current) {
                //@ts-ignore
                scrollableDivRefMobile.current.scrollBy({ top: -100, behavior: 'smooth' });
            }
        } else {
            if (scrollableDivRef.current) {
                //@ts-ignore
                scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
            }
        }
    };

    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const videoRef = useRef(null);
    const videoRefMobile = useRef(null);
    const progressRef: any = useRef(null);
    const progressRefMobile: any = useRef(null);

    const handlePlayPause = () => {
        if(sound) {
            dispatch(SetSound(false))
        }

        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        if (isPlaying) {
            video!.pause();
        } else {
            video!.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const currentTime = video!.currentTime;
        const duration = video!.duration;
        const progressPercent = (currentTime / duration) * 100;
        setProgress(progressPercent);
    };

    const handleDrag = (e: any, data: any) => {
        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const progressBar: any = progressRef.current;
        const newProgress = (data.x / progressBar.offsetWidth) * 100;
        const newTime = (newProgress / 100) * video!.duration;
        video.currentTime = newTime;
        setProgress(newProgress);
    };

    const divRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (divRef.current) {
                const { scrollTop } = divRef.current;
                setIsScrolled(scrollTop > 0);
            }
        };

        const divElement = divRef.current;
        if (divElement) {
            //@ts-ignore
            divElement.addEventListener('scroll', handleScroll);
        }

        // Clean up the event listener on component unmount
        return () => {
            if (divElement) {
                //@ts-ignore
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [divRef, AGModal]);

    return (
        <AnimatePresence>
            {AGModal.open && data && (
                <motion.div
                    ref={drawerRef}
                    key="floor-maps-drawer--root"
                    className="fixed top-0 right-0 bottom-0 w-[500px] bg-[#302e2b] z-[100]"
                    initial={{x: '100%'}}
                    animate={{x: 0}}
                    exit={{x: '100%'}}
                    transition={{duration: 0.5}}
                >
                    <div className="flex flex-col h-full">
                        <section
                            className="flex flex-col pl-[40px] pr-[40px] pt-[40px] pb-[20px] overflow-hidden"
                            style={{
                                backgroundImage: `url("${data.backgroundImage}")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <button onClick={onClose} className="w-max h-max mx-w-[45px] mx-h-[45px] flex flex-col items-center justify-center mb-[20px]">
                                <img src="/images/close-drawer-icon.png" alt="AR icon" className="max-h-[20px] w-auto"/>
                            </button>
                            <h4 className="text-white text-2md font-untitledSans font-bold mb-[10px]">{parseText(data.title)}</h4>
                            <span className="text-white text-xs font-untitledSans mb-[5px]">{parseText(data.category)}</span>
                            <video
                                ref={videoRef}
                                onTimeUpdate={handleTimeUpdate}
                                style={{maxHeight: '250px'}}
                            >
                                <source src={data.video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <div className="flex flex-row items-center gap-[10px] mt-[20px]">
                                <button
                                    onClick={handlePlayPause}
                                    className="cursor-pointer"
                                >
                                    <img src={isPlaying ? `/images/pause-icon.png` : `/images/play-icon.png`} alt="Play icon" className="h-[17px]"/>
                                </button>
                                <div ref={progressRef} className="relative bg-white h-[5px] w-full">
                                    <div
                                        className="bg-[#ec651f] h-full"
                                        style={{width: `${progress}%`}}
                                    ></div>
                                    <Draggable
                                        axis="x"
                                        bounds="parent"
                                        position={{x: (progress / 100) * progressRef.current?.offsetWidth || 0, y: 0}}
                                        onDrag={handleDrag}
                                    >
                                        <div className="absolute top-[-6px] w-[15px] h-[15px] bg-[#ec651f] rounded-full cursor-pointer"></div>
                                    </Draggable>
                                </div>
                            </div>
                        </section>
                        <section className="flex-1 flex flex-col px-[40px] pb-[40px] pt-[20px] overflow-hidden">
                            <div className="overflow-y-auto flex-1">
                                <p onScroll={checkScroll} ref={scrollableDivRef} className="px-[15px] scrollable-div text-white text-xs font-untitledSans text-justify h-full overflow-y-auto">{parseText(data.content)}</p>
                            </div>
                            <section className="mt-[20px] flex flex-row items-center justify-between">
                                {!showScrollUp && <div/>}
                                {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_less')}</button>}
                                {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">{t('vt.global_read_more')}</button>}
                            </section>
                            {data.externalLink && data.externalLink !== "" && <a href={data.externalLink} target="_blank" className="mt-[50px] flex flex-col items-center">
                                <button className="min-w-[250px] border border-[1px] border-white flex flex-col items-center justify-center uppercase text-white text- font-untitledSans px-[50px] py-[8px]">{t('vt.global_external_link')}</button>
                            </a>}
                        </section>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default AGDrawerRoot;